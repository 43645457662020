(function (global) {

	global.viewData = global.viewData || {};

	global.define('jquery', [], function () { return global.jQuery; });
	global.define('pubsub', [], function () { return global.pubsub; });
	global.define('underscore', [], function () { return global._; });
	global.define('BisibiConfig', [], function () { return global.BisibiConfig; });

	var scripts = global.BisibiConfig.getScriptAssets();

	var bundles = {};
	bundles[scripts['common.bundle.js']] = [
		'alert', 'colors', 'confirm', 'console', 'dateFormat', 'dialog', 'floatingAlert', 'hashData', 'hotspotTemplateDialog', 'hotspotTemplateSortDialog',
		'http', 'httpErrorHandler', 'iframeDialog', 'interval', 'localStorage', 'location', 'log', 'messagingFactory', 'objectExtend', 'paginationCalculator',
		'queryString', 'random', 'utils', 'uuid', 'window', 'windowProgressBar'];
	bundles[scripts['form.transformations.bundle.js']] = [
		'formTransformations', 'singleColorSimpleForm'
	];
	bundles[scripts['api.bundle.js']] = [
		'imageApi', 'tagApi', 'templateApi'	
	];
	bundles[scripts['admin.api.bundle.js']] = [     
		'adminTagApi'
	];
    bundles[scripts['buildtemplatecommon.bundle.js']] = [
       'buildTemplateForm', 'savedBuildInitializer'];

    
    bundles[scripts['buildtemplateadmincommon.bundle.js']] = [
     'buildTemplateFormAdmin', 'savedBuildInitializerAdmin'];




	global.require.config({
		baseUrl: '/Scripts/Lib',
        bundles: bundles
	});

})(this);